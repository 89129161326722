import type { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { AxiosHeaders } from 'axios';

import CommonApiBase from '#/interfaces/spark-api/ApiBase';
import type { ApiConfig, ApiMeta } from '#/interfaces/spark-api/types';

const AUTH_URL = '/oauth/token';

export default class ApiBase extends CommonApiBase {
  getRefreshTokenFn: () => Promise<void>;
  getAccessTokenFn: () => string | undefined;
  logoutFn: () => Promise<void>;
  handle409ConflictFn?: () => void;

  constructor(
    config: ApiConfig,
    meta: ApiMeta,
    getRefreshTokenFn: () => Promise<void>,
    getAccessTokenFn: () => string | undefined,
    logoutFn: () => Promise<void>,
    opts: {
      handle409ConflictFn: () => void;
    },
  ) {
    super(config, meta);
    this.getAccessTokenFn = getAccessTokenFn;
    this.getRefreshTokenFn = getRefreshTokenFn;
    this.logoutFn = logoutFn;

    this.handle409ConflictFn = opts?.handle409ConflictFn;
  }

  requestInterceptor(
    config: InternalAxiosRequestConfig,
  ): InternalAxiosRequestConfig {
    let apiToken: string | undefined;
    if (this.getAccessTokenFn) {
      apiToken = this.getAccessTokenFn();
    }

    if (!config.headers) {
      config.headers = new AxiosHeaders();
    }

    if (apiToken) {
      config.headers.Authorization = `Bearer ${apiToken}`;
    } else {
      delete config.headers.Authorization;
    }
    config.headers['X-Client-App-Browsing-ID'] =
      this.meta.getBrowsingSessionId();

    return config;
  }

  responseErrorHandler(error: AxiosError) {
    if (error?.response?.status === 426) {
      try {
        //! calling useMasterDataStore() results in 'TypeError: Super expression must either be null or a function'. caused by circular dependency.
        // todo revisit this to break circular dependency
        // useMasterDataStore().mandatoryUpdateAvailable = true;
        import('@/core/stores/masterData').then((module) => {
          module.useMasterDataStore().mandatoryUpdateAvailable = true;
        });
      } catch (storeError) {
        console.error(storeError);
      }
    }

    if (error?.response?.status === 409) {
      this.handle409ConflictFn?.();
    }

    const isAuthRequest = error?.config?.url?.includes(AUTH_URL);
    const isRetry = error?.config?.headers?.retry_attempt;

    if (error.response?.status === 401 && !isAuthRequest && !isRetry) {
      return this.getRefreshTokenFn().then(() => {
        if (error?.config?.headers) {
          error.config.headers.retry_attempt = 1;
        }
        if (error.config) {
          return this.$http.request(error.config);
        }
      });
    }

    if (error.response?.status === 401 && isRetry) {
      // handle refresh failure
      console.warn('auth error on retry - logging out');
      this.logoutFn();
    } else {
      return Promise.reject(error);
    }
  }
}
