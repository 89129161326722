// Static repo has different CORS settings, so using clean axios instance
// instead of this.$http (no extra default headers)
import axios from 'axios';

import ApiBase from '@/core/services/ApiBase';
import type { StaticResourceEnum } from '@/shared/configs/staticResources';

const BASE_URL = import.meta.env.VITE_APP_SP_STATIC_BASE_URL;

export default class StaticDataService extends ApiBase {
  async getResource(fileName: StaticResourceEnum) {
    return axios.get(`${BASE_URL}/documents/${fileName}`);
  }
}
