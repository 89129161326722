export const AnalyticsEvent: Record<string, string> = {
  // core+common
  REFRESH_409: 'REFRESH_409',
  CONTINUE_409: 'CONTINUE_409',
  PageChange: 'PAGE_CHANGE',
  AppRecoveredFromKnownError: 'APP_RECOVERED_FROM_KNOWN_ERROR',
  AppKnownError: 'APP_ERROR_FROM_KNOWN_ERROR',
  AppErrorCaptured: 'APP_ERROR_CAPTURED',
  GdprConsent: 'GDPR_CONSENT',
  MethodologyOpened: 'METHODOLOGY_OPENED',
  EmailVerificationRequested:
    'EMAIL_VERIFY_RESEND_VERIFICATION_EMAIL_REQUESTED',
  EmailVerificationCompleted:
    'EMAIL_VERIFY_RESEND_VERIFICATION_EMAIL_COMPLETED',
  ACCOUNT_ICE_HANDLE_UPDATE_BTN_CLICKED:
    'ACCOUNT_ICE_HANDLE_UPDATE_BTN_CLICKED',
  MarketingBannerClicked: 'BANNER_CLICKED',
  LoginBtnClicked: 'LOGIN_SIGNIN_BTN_CLICKED',
  LoginWithPassword: 'LOGIN_WITH_PASS',
  LoginSuccess: 'LOGIN_SUCCESS',
  LoginError: 'LOGIN_ERROR',
  LoginFailure: 'LOGIN_SIGNIN_FAILURE',
  Logout: 'LOGOUT',
  LogoutFailure: 'LOGOUT_ATTEMPT_FAILURE',
  PasswordChangeRequested: 'PASSWORD_CHANGE_REQUESTED',
  PasswordChangeCompleted: 'PASSWORD_CHANGE_COMPLETED',
  PasswordChangeError: 'PASSWORD_CHANGE_ERROR',
  PasswordResetRequested: 'PASSWORD_RESET_REQUESTED',
  PasswordResetCompleted: 'PASSWORD_RESET_COMPLETED',
  PasswordResetError: 'PASSWORD_RESET_ERROR',

  SignupRequested: 'SIGNUP_REGISTRATION_REQUESTED',
  SignupComplete: 'SIGNUP_REGISTRATION_COMPLETE',
  SignupAutoLoginError: 'SIGNUP_AUTOLOGIN_ERROR',
  SignupFailed: 'SIGNUP_REGISTRATION_FAILED',
  SignupError: 'SIGNUP_REGISTRATION_ERROR',

  // polls
  PollSubmission: 'POLL_USER_SUBMIT_CHOICE',
  PollExpandToggle: 'POLL_EXPAND_COLLAPSE',

  // basis
  BasisDealsToggle: 'BASIS_DEALS_TOGGLE',
  BasisMorePageItemsClick: 'BASIS_MORE_PAGE_ITEM_LINK_CLICK',

  SettlementCriteriaChange: 'SETTLEMENT_CRITERIA_CHANGE',

  // ffa submission
  FFASubmissionJustificationChanged: 'FFA_SUBMISSION_JUSTIFICATION_CHANGED',
  FFASubmissionReviewBtnClicked: 'FFA_SUBMISSION_REVIEW_BTN_CLICKED',
  FFASubmissionError: 'FFA_SUBMISSION_ERROR',
  FFASubmissionBtnClicked: 'FFA_SUBMISSION_BTN_CLICKED',
  FFASubmissionFinished: 'FFA_SUBMISSION_FINISHED',
  FFASubmissionGoBack: 'FFA_SUBMISSION_BACK_NAVIGATION',

  FFASubmissionCancelOnReview: 'FFA_SUBMISSION_CANCELLED_ON_REVIEW',
  FFASubmissionChartPriceTypeChanged: 'FFA_SUBMISSION_CHART_DIMENSION_CHANGED',
  FFASubmissionChartToggled: 'FFA_SUBMISSION_CHART_TOGGLED',
  FFASubmissionImportPricesModalToggle:
    'FFA_SUBMISSION_TOGGLE_IMPORT_PRICES_MODAL',
  FFASubmissionImportPricesFromExcelApply:
    'FFA_SUBMISSION_IMPORT_PRICES_FROM_EXCEL_APPLY',

  // submission
  SubmissionJustificationChanged: 'SUBMISSION_JUSTIFICATION_CHANGED',
  SubmissionSegmentToggled: 'SUBMISSION_SEGMENT_TOGGLED',
  SubmissionReviewBtnClicked: 'SUBMISSION_REVIEW_BTN_CLICKED',
  SubmissionError: 'SUBMISSION_ERROR',
  SubmissionFinished: 'SUBMISSION_FINISHED',
  SubmissionBtnClicked: 'SUBMISSION_BTN_CLICKED',
  SubmissionGoBack: 'SUBMISSION_BACK_NAVIGATION',
  SubmissionExitModalToggled: 'SUBMISSION_EXIT_MODAL_TOGGLED',
  SubmissionCancelOnReview: 'SUBMISSION_CANCELLED_ON_REVIEW',
  SubmissionChartPriceTypeChanged: 'SUBMISSION_CHART_DIMENSION_CHANGED',
  SubmissionChartToggled: 'SUBMISSION_CHART_TOGGLED',
  SubmissionImportPricesModalToggle: 'SUBMISSION_TOGGLE_IMPORT_PRICES_MODAL',
  SubmissionImportPricesFromExcelApply:
    'SUBMISSION_IMPORT_PRICES_FROM_EXCEL_APPLY',
  SubmissionRTCalcRatesApplied: 'SUBMISSION_RT_CALC_RATES_APPLIED',

  // xls download
  ExcelDownloadRequest: 'DISCOVER_DOWNLOAD_REQUESTED',
  ExcelDownloadComplete: 'DISCOVER_DOWNLOAD_COMPLETE',
  ExcelDownloadFailed: 'DISCOVER_DOWNLOAD_FAILED',

  // discover page
  PriceChartPriceTypeComparisonChanged: 'PRICE_DISCOVERY_COMPARISON_CHANGED',
  ReleaseDateChanged: 'DISCOVER_RELEASE_DATE_CHANGED',
  ReleaseInfoOpened: 'DISCOVER_RELEASE_INFO_POPUP_OPENED',
  ActionBarButtonClicked: 'ACTION_BAR_BTN_CLICKED',
  SubmissionStatusRefresh: 'REFRESH_SUBMISSION_STATUS',
  UnitChanged: 'UNIT_CHANGED',
  FFAActionBarButtonClicked: 'FFA_ACTION_BAR_BTN_CLICKED',

  // price-evolution
  PriceEvolutionComparisonMonthChanged:
    'PRICE_EVOLUTION_COMPARISON_MONTH_CHANGED',
  PriceEvolutionFiltersContractChange:
    'PRICE_EVOLUTION_FILTERS_CONTRACT_CHANGED',
  PriceEvolutionFiltersContractTypeChange:
    'PRICE_EVOLUTION_FILTERS_CONTRACT_TYPE_CHANGED',
  PriceEvolutionFiltersUnitToggled: 'PRICE_EVOLUTION_FILTERS_UNIT_TOGGLED',
  PriceEvolutionFiltersDelMonthChange:
    'PRICE_EVOLUTION_FILTERS_DEL_MONTH_CHANGED',
  PriceEvolutionCustomToggled: 'PRICE_EVOLUTION_CUSTOM_TOGGLED',
  PriceEvolutionFiltersPercentToggled:
    'PRICE_EVOLUTION_FILTERS_PERCENT_TOGGLED',
  PriceEvolutionDurationChange: 'PRICE_EVOLUTION_DURATION_CHANGE',
  PriceEvolutionTerminalChange: 'PRICE_EVOLUTION_TERMINAL_CHANGE',
  PriceEvolutionSlotTypeChange: 'PRICE_EVOLUTION_SLOT_TYPE_CHANGE',

  // ffa-price-evolution
  FFAPriceEvolutionDurationChange: 'FFA_PRICE_EVOLUTION_DURATION_CHANGE',
  FFAPriceEvolutionFiltersContractChange:
    'FFA_PRICE_EVOLUTION_FILTERS_CONTRACT_CHANGED',
  FFAPriceEvolutionFiltersDelMonthChange:
    'FFA_PRICE_EVOLUTION_FILTERS_DEL_MONTH_CHANGED',

  // notes
  DiscoverNotesSaved: 'NOTES_SAVED_DISCOVER',
  DiscoverNotesLoaded: 'NOTES_LOADED_DISCOVER',
  NetbackNotesSaved: 'NOTES_SAVED_NETBACK_DISCOVER',
  NetbackNotesLoaded: 'NOTES_LOADED_NETBACK_DISCOVER',
  RoutesNotesSaved: 'NOTES_SAVED_ROUTES_DISCOVER',
  RoutesNotesLoaded: 'NOTES_LOADED_ROUTES_DISCOVER',
  FreightNotesSaved: 'NOTES_SAVED_PHYSICAL_DISCOVER',
  FreightNotesLoaded: 'NOTES_LOADED_PHYSICAL_DISCOVER',
  FFANotesSaved: 'NOTES_SAVED_FFA_DISCOVER',
  FFANotesLoaded: 'NOTES_LOADED_FFA_DISCOVER',
  FloatingStorageNotesSaved: 'NOTES_SAVED_FLOATING_STORAGE',
  FloatingStorageNotesLoaded: 'NOTES_LOADED_FLOATING_STORAGE',
  NetForwardsNotesSaved: 'NOTES_SAVED_NETFORWARDS',
  NetForwardsNotesLoaded: 'NOTES_LOADED_NETFORWARDS',
  NetbackArbNotesSaved: 'NOTES_SAVED_NETBACK_ARBS',
  NetbackArbNotesLoaded: 'NOTES_LOADED_NETBACK_ARBS',

  // ffa
  FFADiscoverNotesSaved: 'NOTES_SAVED_FFA_DISCOVER',
  FFADiscoverNotesLoaded: 'NOTES_LOADED_FFA_DISCOVER',
  FFADiscoverPriceTypeChanged: 'FFA_DISCOVER_PRICE_FILTER_DIMENSION_TOGGLED',

  // spread
  SpreadFiltersChange: 'SPREAD_FILTERS_CHANGED',
  SpreadFiltersUnitChange: 'SPREAD_FILTERS_UNIT_TOGGLED',

  // routes
  RoutesCriteriaUpdated: 'ROUTES_CRTIERIA_UPDATED',
  RoutesCriteriaCongestionDaysUpdated:
    'ROUTES_CRITERIA_CONGESTION_DAYS_UPDATED',

  // netbacks
  NetbacksCriteriaUpdated: 'NETBACKS_CRTIERIA_UPDATED',
  NetbacksCriteriaCongestionDaysUpdated:
    'NETBACKS_CRITERIA_CONGESTION_DAYS_UPDATED',

  // ffa spread
  FFASpreadFiltersChange: 'FFA_SPREAD_FILTERS_CHANGED',

  // settlement
  SettlementMonthChange: 'SETTLEMENT_MONTH_CHANGE',
  SettlementContractChange: 'SETTLEMENT_CONTRACT_CHANGE',
  SettlementContractTypeChange: 'SETTLEMENT_CONTRACT_TYPE_CHANGE',
  SettlementMaxLossChange: 'SETTLEMENT_MAX_LOSS_CHANGE',
  SettlementMaxGainChange: 'SETTLEMENT_MAX_GAIN_CHANGE',

  // freight price evolution
  FreightPriceEvolutionSignalChange: 'FREIGHT_PRICE_EVOLUTION_SIGNAL_CHANGE',
  FreightPriceEvolutionDurationChange:
    'FREIGHT_PRICE_EVOLUTION_DURATION_CHANGE',
  FreightPriceEvolutionCompareFoChange:
    'FREIGHT_PRICE_EVOLUTION_COMPARE_FO_CHANGE',
  FreightPriceEvolutionCustomRangeChange:
    'PRICE_EVOLUTION_CUSTOM_RANGE_CHANGED',

  // freight discover signals
  FreightSpotSignalsToggled: 'DISCOVER_SPOT_SIGNALS_TOGGLED',

  // round trip calc
  RoundtripCalcSubmitBtnClick: 'RT_CALCULATOR_SUBMIT_BTN_CLICK',
  RoundtripCalcRemoveRate: 'RT_CALCULATOR_REMOVE_RATE',

  // trial
  StartTrialRequest: 'FREE_TRIAL_PAGE_START_TRIAL',
  TrialPageClosed: 'FREE_TRIAL_PAGE_CLOSED_SUCCESS',
  TrialPageClosureFailed: 'FREE_TRIAL_PAGE_CLOSED_ERROR',

  // more/documentations
  DocumentationLinkClicked: 'GOVERNANCE_AND_MORE_LINK_CLICK',

  // dashboard
  LaunchedViaDashboardPage: 'DASHBOARD_LAUNCH_PAGE',
  DashboardSignalsContractTypeChange: 'DASHBOARD_SIGNALS_CONTRACTTYPE_CHANGE',
  DashboardSignalsContractIdChange: 'DASHBOARD_SIGNALS_CONTRACTID_CHANGE',
  DashboardPDContractChange: 'DASHBOARD_PD_CONTRACT_SWITCH',
  DashboardPDMethodologyOpened: 'DASHBOARD_PD_METHODOLOGY_OPENED',
  DashboardUnitToggle: 'DASHBOARD_UNIT_TOGGLE',
  DashboardDerivateToggle: 'DASHBOARD_DERIVATIVE_TOGGLE',

  // basic-dashboard
  DashboardExplorePremium: 'BASIC_DASHBOARD_TOGGLE_EXPLORE_PREMIUM',
  DashboardPDFDownload: 'BASIC_DASHBOARD_CLICK_DOWNLOAD_PDF',
  DashboardDiscussPremium: 'BASIC_DASHBOARD_CLICK_DISCUSS_PREMIUM',
  DashboardViewFullDetails: 'BASIC_DASHBOARD_CLICK_VIEW_FULL_DETAILS,',

  // banner
  BannerClick: 'BANNER_CLICK',

  // access/terminal-map
  AccessWindow: 'ACCESS_WINDOW',
  MapPinTerminal: 'MAP_PIN_TERMINAL',
  MapUnpinTerminal: 'MAP_UNPIN_TERMINAL',
  MapHoverGashub: 'MAP_HOVER_GASHUB',
  MapTerminalComparison: 'MAP_TERMINAL_COMPARISON',
  MapHoverTerminal: 'MAP_HOVER_TERMINAL',
  MapUnitChange: 'MAP_UNIT_CHANGE',
  MapFeedbackModal: 'MAP_FEEDBACK_MODAL',

  // access/table-view
  ComparisonTableCsvModal: 'ComparisonTableCsvModal',

  // announcement
  AnnouncementShown: 'ANNOUNCEMENT_SHOWN',
  AnnouncementAcknowledged: 'ANNOUNCEMENT_ACKNOWLEDGED',
  AnnouncementContactUs: 'ANNOUNCEMENT_CONTACT_US',

  // recent update
  RecentUpdateClicked: 'RECENT_UPDATE_CLICKED',

  // netbacks arb
  NetbacksArbCriteriaUpdated: 'NETBACKS_ARB_CRITERIA_UPDATED',
  NetbacksArbCriteriaCongestionDaysUpdated:
    'NETBACKS_ARB_CRITERIA_CONGESTION_DAYS_UPDATED',

  // page prompts
  PagePromptsLinkClicked: 'PAGE_PROMPTS_LINK_CLICKED',
};
