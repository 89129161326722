import { isNil } from 'lodash';
import { defineStore, storeToRefs } from 'pinia';
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';

import { crossProductFeatures } from '@/core/components/the-nav/mainMenuConfig';
import { config } from '@/core/components/the-nav/subMenuConfig';
import { useAuthStore } from '@/core/stores/auth';
import { useChangelogStore } from '@/products/access/stores/changelog';
import { features, permissions } from '@/shared/misc/constants';
import type { components } from '@/types';
import type { Nullable } from '#/types/core';
import { replacePlaceholders } from '#/utils/string';

import { CONTRACTS, TOOLS } from '../components/the-nav/constants';
import { useFeatureVersionStore } from './featureVersion';

export type MenuItem = {
  title: string;
  to?: string;
  icon: string;
  subTitle?: string;
  isLocked?: boolean;
  showRedDot: boolean;
  uiTourId?: string;
  items?: {
    title: string;
    to: string;
    showRedDot: boolean;
  }[];
};

export type CallToActionContext = {
  renderAs: 'a' | 'router-link';
  href: string;
  description: string;
  openCrispChatIfAvailable?: boolean;
  preFillCrispChatMessage?: string;
};

const useMenuStore = defineStore('menu', () => {
  const featureVersionStore = useFeatureVersionStore();
  const authStore = useAuthStore();
  const route = useRoute();

  const { shouldShowRedDot, dismissFeature } = featureVersionStore;
  const { freightPlan, cargoPlan, accessPlan, isSparkUser } =
    storeToRefs(authStore);
  const { isFeatureLocked } = authStore;

  const freightContracts = computed(() => {
    const contracts =
      config.freight
        .find((f) => f.title === CONTRACTS)
        ?.items.map<MenuItem>((contractCategory) => {
          return {
            title: contractCategory.title,
            to: contractCategory.to,
            icon: contractCategory.icon,
            subTitle: contractCategory.subTitle,
            isLocked: isFeatureLocked(
              contractCategory.permission,
              'lng-freight-platform',
              contractCategory.compareUsing,
            ),
            showRedDot: contractCategory.feature
              ? shouldShowRedDot(contractCategory.feature, route?.name)
              : false,
            uiTourId: contractCategory.uiTourId,
            items: contractCategory.items?.map((subItem) => {
              return {
                title: subItem.title,
                to: subItem.to,
                showRedDot: shouldShowRedDot(subItem.feature, route?.name),
              };
            }),
          };
        }) ?? [];

    if (freightPlan.value === 'basic-plan') {
      const basicDashboard = {
        title: 'Dashboard',
        icon: 'dashboard',
        isLocked: false,
        showRedDot: false,
        items: [
          {
            title: 'Spark30',
            to: '/freight/basic-dashboard/spark30',
            feature: features.FREIGHT_BASIC_DASHBOARD,
            showRedDot: shouldShowRedDot(
              features.FREIGHT_BASIC_DASHBOARD,
              route?.name,
            ),
          },
          {
            title: 'Spark25',
            to: '/freight/basic-dashboard/spark25',
            feature: features.FREIGHT_BASIC_DASHBOARD,
            showRedDot: shouldShowRedDot(
              features.FREIGHT_BASIC_DASHBOARD,
              route?.name,
            ),
          },
        ],
      };
      contracts.splice(0, 0, basicDashboard);
    }

    return contracts;
  });

  const freightTools = computed(() => {
    const tools =
      config.freight
        .find((f) => f.title === TOOLS)
        ?.items.map<MenuItem>((tool) => {
          return {
            title: tool.title,
            to: tool.to,
            icon: tool.icon,
            subTitle: tool.subTitle,
            isLocked: isFeatureLocked(
              tool.permission,
              'lng-freight-platform',
              tool.compareUsing,
            ),
            showRedDot: tool.feature
              ? shouldShowRedDot(tool.feature, route?.name)
              : false,
            items: tool.items?.map((subItem) => {
              return {
                title: subItem.title,
                to: subItem.to,
                showRedDot: shouldShowRedDot(subItem.feature, route?.name),
              };
            }),
          };
        }) ?? [];

    //add global-calc temporararily for spark users only
    if (isSparkUser.value) {
      tools.splice(0, 0, {
        title: 'Global Calc',
        to: '/freight/global-calculator',
        icon: 'calculator',
        isLocked: false,
        subTitle: '',
        showRedDot: false,
      });
    }

    return tools;
  });

  const freightMenu = computed(() => [
    {
      title: CONTRACTS,
      items: freightContracts.value,
    },
    {
      title: TOOLS,
      items: freightTools.value,
    },
  ]);

  const freightHasRedDot = computed(() => {
    const hasFreightSubscription =
      !isNil(freightPlan.value) && freightPlan.value !== 'no-plan';

    const hasRedDotChildren =
      freightContracts.value.some((item) => item.showRedDot) ||
      freightContracts.value.some((item) =>
        item.items?.some((subItem) => subItem.showRedDot),
      ) ||
      freightTools.value.some((item) => item.showRedDot);

    return hasFreightSubscription && hasRedDotChildren;
  });

  const freightCallToActions = computed(() => {
    const actions: CallToActionContext[] = [];

    if (freightPlan.value === 'basic-plan') {
      actions.push({
        renderAs: 'router-link',
        href: '/freight-intro',
        description: 'Upgrade to enjoy all features',
      });
    }

    if (freightPlan.value === 'trial-plan') {
      actions.push({
        renderAs: 'a',
        href: 'mailto:info@sparkcommodities.com',
        description: getUpgradeText('lng-freight-platform'),
        openCrispChatIfAvailable: true,
        preFillCrispChatMessage: 'I would like to upgrade my trial plan',
      });
    }

    return actions;
  });

  const cargoContracts = computed(() => {
    const contracts =
      config.cargo
        .find((f) => f.title === CONTRACTS)
        ?.items.map<MenuItem>((contractCategory) => {
          return {
            title: contractCategory.title,
            to: contractCategory.to,
            icon: contractCategory.icon,
            subTitle: contractCategory.subTitle,
            isLocked: isFeatureLocked(
              contractCategory.permission,
              'lng-basis-platform',
              contractCategory.compareUsing,
            ),
            showRedDot: contractCategory.feature
              ? shouldShowRedDot(contractCategory.feature, route?.name)
              : false,
            uiTourId: contractCategory.uiTourId,
            items: contractCategory.items?.map((subItem) => {
              return {
                title: subItem.title,
                to: subItem.to,
                showRedDot: shouldShowRedDot(subItem.feature, route?.name),
              };
            }),
          };
        }) ?? [];

    if (
      !isFeatureLocked(
        permissions.ACCESS_SPARK_CLOSE_SANDBOX,
        'lng-basis-platform',
        'includes',
      )
    ) {
      contracts.push({
        title: 'SparkClose',
        to: '/cargo/sandbox/spark-close/',
        icon: 'pricediscovery',
        subTitle: 'SANDBOX',
        showRedDot: shouldShowRedDot(
          features.BASIS_SPARK_CLOSE_SANDBOX,
          route?.name,
        ),
      });
    } else {
      contracts.push({
        title: 'SparkClose',
        to: '/cargo/spark-close-sandbox-intro/',
        icon: 'pricediscovery',
        subTitle: 'SANDBOX',
        showRedDot: shouldShowRedDot(
          features.BASIS_SPARK_CLOSE_SANDBOX_INTRO,
          route?.name,
        ),
      });
    }

    return contracts;
  });

  const cargoTools = computed(() => {
    return (
      config.cargo
        .find((f) => f.title === TOOLS)
        ?.items.map<MenuItem>((tool) => {
          return {
            title: tool.title,
            to: tool.to,
            icon: tool.icon,
            subTitle: tool.subTitle,
            isLocked: isFeatureLocked(
              tool.permission,
              'lng-basis-platform',
              tool.compareUsing,
            ),
            showRedDot: tool.feature
              ? shouldShowRedDot(tool.feature, route?.name)
              : false,
            items: tool.items?.map((subItem) => {
              return {
                title: subItem.title,
                to: subItem.to,
                showRedDot: shouldShowRedDot(subItem.feature, route?.name),
              };
            }),
          };
        }) ?? []
    );
  });

  const cargoMenu = computed(() => [
    {
      title: CONTRACTS,
      items: cargoContracts.value,
    },
    {
      title: TOOLS,
      items: cargoTools.value,
    },
  ]);

  const cargoHasRedDot = computed(() => {
    const hasCargoSubscription =
      !isNil(cargoPlan.value) && cargoPlan.value !== 'no-plan';

    const hasRedDotChildren =
      cargoContracts.value.some((item) => item.showRedDot) ||
      cargoContracts.value.some((item) =>
        item.items?.some((subItem) => subItem.showRedDot),
      ) ||
      cargoTools.value.some((item) => item.showRedDot);

    return hasCargoSubscription && hasRedDotChildren;
  });

  const cargoCallToActions = computed(() => {
    const actions: CallToActionContext[] = [];

    if (cargoPlan.value === 'basic-plan') {
      actions.push({
        renderAs: 'router-link',
        href: '/cargo-intro',
        description: 'Upgrade to enjoy all features',
      });
    }

    if (cargoPlan.value === 'trial-plan') {
      actions.push({
        renderAs: 'a',
        href: 'mailto:info@sparkcommodities.com',
        description: getUpgradeText('lng-basis-platform'),
        openCrispChatIfAvailable: true,
        preFillCrispChatMessage: 'I would like to upgrade my trial plan',
      });
    }

    return actions;
  });

  const accessTools = computed(() => {
    return (
      config.access
        .find((f) => f.title === TOOLS)
        ?.items.map<MenuItem>((tool) => {
          return {
            title: tool.title,
            to: tool.to,
            icon: tool.icon,
            subTitle: tool.subTitle,
            isLocked: isFeatureLocked(
              tool.permission,
              'access-platform',
              tool.compareUsing,
            ),
            showRedDot: tool.feature
              ? shouldShowRedDot(tool.feature, route?.name) ||
                (tool.feature === features.ACCESS_CHANGELOG &&
                  useChangelogStore().hasUpdate())
              : false,
            items: tool.items?.map((subItem) => {
              return {
                title: subItem.title,
                to: subItem.to,
                showRedDot: shouldShowRedDot(subItem.feature, route?.name),
              };
            }),
          };
        }) ?? []
    );
  });

  const accessMenu = computed(() => [
    {
      title: TOOLS,
      items: accessTools.value,
    },
  ]);

  const accessHasRedDot = computed(() => {
    const hasAccessSubscription =
      !isNil(accessPlan.value) && accessPlan.value !== 'no-plan';

    const hasRedDotChildren =
      accessTools.value.some((item) => item.showRedDot) ||
      accessTools.value.some((item) =>
        item.items?.some((subItem) => subItem.showRedDot),
      );

    return hasAccessSubscription && hasRedDotChildren;
  });

  const accessCallToActions = computed(() => {
    const actions: CallToActionContext[] = [];

    if (accessPlan.value === 'trial-plan') {
      actions.push({
        renderAs: 'a',
        href: 'mailto:info@sparkcommodities.com',
        description: getUpgradeText('access-platform'),
        openCrispChatIfAvailable: true,
        preFillCrispChatMessage: 'I would like to upgrade my trial plan',
      });
    }

    return actions;
  });

  const crossProductFeaturesMenu = computed(() => {
    return crossProductFeatures
      .filter((item) => {
        if (item.permission && item.permission.compareLogic === 'OR') {
          return item.permission.definitions.some((def) =>
            def.compareUsing === 'equals'
              ? authStore.hasPermission(def.permission, def.product)
              : authStore.hasPermissionsAmong(def.permission, def.product),
          );
        } else if (item.permission && item.permission.compareLogic === 'AND') {
          return item.permission.definitions.every((def) =>
            def.compareUsing === 'equals'
              ? authStore.hasPermission(def.permission, def.product)
              : authStore.hasPermissionsAmong(def.permission, def.product),
          );
        }

        if (item.showOnlyForSparkUsers) {
          return isSparkUser.value;
        }
        return true;
      })
      .map<MenuItem>((item) => {
        const to =
          item.redirectLogic?.(authStore.availableSubscriptions) ?? item.to;

        return {
          title: item.title,
          to,
          icon: item.icon,
          showRedDot: false,
        };
      });
  });

  function getTrialDaysLeft(product: components['schemas']['ProductEnum']) {
    const subscription = authStore.availableSubscriptions.find(
      (s) => s.product === product,
    );
    let daysLeft: Nullable<number> = null;
    if (subscription && subscription.plan === 'trial-plan') {
      daysLeft = authStore.getTrialDaysLeft(subscription);
    }
    return daysLeft;
  }

  function getUpgradeText(product: components['schemas']['ProductEnum']) {
    const daysLeft = getTrialDaysLeft(product);
    if (daysLeft !== null && daysLeft !== undefined && daysLeft > 0) {
      const daysOrDay = daysLeft > 1 ? `days` : `day`;
      return `${daysLeft} ${daysOrDay} left. Upgrade Now!`;
    }
    return 'Upgrade Now!';
  }

  function handleDismissFeature() {
    // handle feature id with params
    if (route.meta.featureIdTemplate && route.params) {
      const keyValues: Record<string, string> = {};

      for (const key in route.params) {
        const value = route.params[key];
        if (typeof value === 'string') {
          keyValues[key] = value;
        }
      }

      const featureId = replacePlaceholders(
        route.meta.featureIdTemplate,
        keyValues,
      );

      dismissFeature({ featureId });
    }
    // else dismiss feature by route name (default behavior)
    else if (typeof route.name === 'string') {
      dismissFeature({ featureId: route.name });
    }
  }

  watch(
    () => route.path,
    () => {
      handleDismissFeature();
    },
    { immediate: true },
  );

  return {
    freightContracts,
    freightTools,
    freightHasRedDot,
    freightCallToActions,
    freightMenu,
    cargoContracts,
    cargoTools,
    cargoHasRedDot,
    cargoCallToActions,
    cargoMenu,
    accessTools,
    accessCallToActions,
    accessMenu,
    accessHasRedDot,
    crossProductFeaturesMenu,
  };
});

export { useMenuStore };
